import "react-datepicker/dist/react-datepicker.css";
import './ComputerList.css'
import React from 'react'
import DatePicker from 'react-datepicker'

class ComputerList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            enabled: props.authManager.status.isAuthenticated,
            busy: false,
            id: (performance.now().toString(36)+Math.random().toString(36)).replace(/\./g,""),
            computers: [],
            selectedComputers: {},
            subject: '',
            localUsername: '',
            localPassword: '',
            endDate: new Date(),
            sendMail: true,
            reportTo: '',
            ccTo: 'it@cornerstone.se'
        }

        let self = this
        let reload = (cb) => {
            self.reloadComputers(() => setTimeout(() => cb(cb), 1000))
        }

        if (this.state.enabled) {
            reload(reload)
        }
    }

    reloadComputers(cb) {
        let self = this
        this.props.computerManager.loadComputers(computers => {
            self.setState({computers})
            cb(computers)
        })
    }

    publish() {
        let recipients = {}
        let computerIds = Object.keys(this.state.selectedComputers)

        this.state.busy = true

        let computerRows = computerIds.map(id => {
            let c = this.state.selectedComputers[id]
            recipients[c.computer.name] = c.input.value
            c.lock()
            return c
        })

        let settings = {
            subject: this.state.subject,
            endDate: this.state.endDate.toISOString(),
            localUsername: this.state.localUsername,
            localPassword: this.state.localPassword,
            recipients: recipients
        }

        if (this.state.sendMail) {
            if (this.state.reportTo || this.state.ccTo) {
                let mailOptions = {}
                if (this.state.reportTo) {
                    mailOptions.reportTo = this.state.reportTo
                }
                if (this.state.ccTo) {
                    mailOptions.ccTo = this.state.ccTo
                }
                settings.mailOptions = mailOptions
            } else {
                settings.mailOptions = true
            }
        }

        let self = this
        this.props.computerManager.publish(
            settings,
            () => {
                self.state.busy = false
                computerRows.forEach(computerRow => {
                    computerRow.unlock()
                    computerRow.deselect()
                })
            }
        )
    }

    checkValidity() {
        let valid =  (this.state.computers.length > 0) && (this.state.subject.length > 0) && (this.state.localUsername.length > 0) && (this.state.localPassword.length > 0) && (this.state.endDate) && (Object.keys(this.state.selectedComputers).length > 0)
        return valid
    }

    render() {
        let self = this

        if (!this.state.enabled) {
            return
        }

        if (this.state.busy) {
            return <div className="ComputerList-overlay">
                <div className="ComputerList-overlay-message">
                    <p>Busy</p>
                </div>
            </div>
        }

        return (
            <React.Fragment>
            <div className="ComputerList-controls" id={'ComputerList-controls-' + this.state.id} >
                <p>End date:</p>
                <DatePicker className="ComputerList-controls-endDate" inline dateFormat = "dd-MM-yyyy" selected={this.state.endDate} onChange={(date) => this.setState({endDate: date})} />
                <label>
                    Subject:
                    <input type="text" value = { this.state.subject } onChange={(e) => { this.setState({'subject': e.target.value}) }} />
                </label>
                <label>
                    Username:
                    <input type="text" value={ this.state.localUsername } onChange={(e) => { this.setState({localUsername: e.target.value}) }} />
                </label>
                <label>
                    Password:
                    <input type="text" value={this.state.localPassword} onChange={(e) => this.setState({localPassword: e.target.value})} />
                </label>
                <label>
                    Send Mail:
                    <input type="checkbox" value={this.state.sendMail} onChange={(e) => this.setState({sendMail: e.target.checked})} checked={this.state.sendMail} />
                </label>
                <label>
                    Report To:
                    <input type="email" value={this.state.reportTo} onChange={(e) => this.setState({reportTo: e.target.value})} />
                </label>
                <label>
                   CC To:
                    <input type="email" value={this.state.ccTo} onChange={(e) => this.setState({ccTo: e.target.value})} />
                </label>
                <label>
                    <input type="button" value={ this.state.sendMail ? "Send" : "Assign" } onClick={ () => { self.publish() } } disabled={!this.checkValidity()} />
                </label>
            </div>
            <div className="ComputerList-computersWrapper">
            <table className={ "ComputerList-computersTable" } id={ "ComputerList-" + this.state.id + "-computersTable"} >
                <thead>
                    <tr>
                        <td></td>
                        <td>ID</td>
                        <td>Name</td>
                        <td>Valid Until</td>
                        <td>Holder</td>
                        <td>Subject</td>
                    </tr>
                </thead>
                <tbody className={ "ComputerList-computers" } id={ "ComputerList-" + this.state.id + "-computers" } >
                    {
                        this.state.computers.map(computer => {
                            return <ComputerRow key={computer.id} listId = {this.state.id} selectList = {this.state.selectedComputers} computer = {computer}/>
                        })
                    }
                </tbody>
            </table>
            </div>
            </React.Fragment>
        )
    }
}

class ComputerRow extends React.Component {
    constructor(props){
        super(props)

        let computer = props.computer

        this.state = {
            selected: false,
            locked: false,
            email: computer.assignment ? computer.assignment.assignee : "",
            endDate: computer.assignment ? computer.assignment.validUntil : "",
            subject: computer.assignment ? computer.assignment.subject : "",
            id: `computer-${props.listId}-${props.computer.id}`
        }
    }

    lockSelection() {
        this.state.locked = true
    }

    unlockSelection() {
        this.state.locked = false
    }

    toggleSelect(e) {

        if (this.state.locked) {
            return
        }

        if (this.state.selected && e) {
            if (e.target.nodeName === 'INPUT' && e.target.type === 'text') {
                return
            }
        }

        let emailElemId = this.state.id + '-email'
        let computer = this.props.computer
        
        let selected = !this.state.selected
        this.setState({'selected': selected})
        if (selected) {
            let self = this
            this.props.selectList[computer.id] = {
                computer: computer,
                input: document.getElementById(emailElemId),
                deselect: () => self.toggleSelect(),
                lock: () => self.lockSelection(),
                unlock: () => self.unlockSelection()
            }
        } else {
            if (this.props.selectList[computer.id]) {
                delete this.props.selectList[computer.id]
            }
        }
    }
    
    render() {
        let computer = this.props.computer

        let emailElemId = this.state.id + '-email'

        let classList = []

        if (this.state.selected) {
            classList.push('selected')
        }

        if (computer.assigned) {
            classList.push('assigned')
        }

        return (
            <tr className={ classList.join(' ') } onClick={(e) => this.toggleSelect(e)}>
                <td><input type="checkbox" disabled={this.state.locked} checked={this.state.selected} onChange={e => this.setState({selected: e.target.checked})} /></td>
                <td className = "ComputerList-computer-id">{computer.id}</td>
                <td className = "ComputerList-computer-name">{computer.name}</td>
                <td className = "ComputerList-computer-endDate">{computer.assignment ? computer.assignment.validUntil : ""}</td>
                <td className = "ComputerList-computer-email"><input id={emailElemId} className="fill" disabled = {!this.state.selected} value = { this.state.email } onChange = { (e) => { this.setState({'email': e.target.value}) } } type="email" /></td>
                <td className = "ComputerList-computer-subject">{computer.assignment ? computer.assignment.subject : ""}</td>
            </tr>
        )
    }
}

export default ComputerList