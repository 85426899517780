import './App.css';
import React from 'react';
import ComputerManager from './ComputerManager'
import TopBar from './Components/TopBar';
import ComputerList from './Components/ComputerList'
import Footer from './Components/Footer';


class App extends React.Component {
    render() {
        let authManager = this.props.authManager
        return <React.Fragment>
            <div id="topbar">
                <TopBar authManager = {authManager} />
            </div>
            <div id="content">
                <ComputerList authManager = {authManager} computerManager = {new ComputerManager()}/>
            </div>
            <div id="footer">
                <Footer authManager = {authManager} />
            </div>
        </React.Fragment>
    }
}

export default App;
