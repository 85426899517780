
import React from 'react'

class TopBar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isAuthenticated: props.authManager.status.isAuthenticated
        }
    }

    render() {
        return <React.Fragment>
            { !this.state.isAuthenticated && <a className="login-button" href="/auth/openid/login">Log in</a> }
            { /* this.state.isAuthenticated && <a className="logout-button" href="/auth/logout">Log Out</a> */ }
            { /* this.state.isAuthenticated && <a className="login-whoamibutton" href="/auth/openid/whoami">WhoAmI</a> */ }
        </React.Fragment>
    }
}

export default TopBar