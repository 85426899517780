import React from 'react'
class Footer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isAuthenticated: props.authManager.status.isAuthenticated,
            user: props.authManager.status.user
        }
    }

    render() {
        if (this.state.user) {
            return <p>{`Logged in as: ${this.state.user.odata.userPrincipalName}`}</p>
        } else {
            return <p>Not logged in.</p>
        }
    }
}

export default Footer