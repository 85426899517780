class AuthManager {

    status = {isAuthenticated: false}

    checkLoginStatus(cb) {
        let self = this
        let req = new XMLHttpRequest()
        req.open('get', `/auth/status`)
        req.onload = (res) => {
            switch(res.target.status) {
                case 200:
                    let data = JSON.parse(res.target.response)

                    self.status = data

                    if (data.isAuthenticated) {
                        console.log('Authenticated')
                    } else {
                        console.log('Unauthenticated')
                    }

                    if (typeof cb === 'function') {
                        cb(data)
                    }
                    break
                default:
                    console.log(`Unexpected response code: ${res.target.status}`)
                    break
            }
        }
        req.send()
    }
}

export default AuthManager