class ComputerManager {

    /**
     * 
     * @param {*} settings Object determining settings for the publishing:
     *  {
     *      subject: Subject string to include [string]
     *      endDate: Date when the published connection details should expire [string].
     *      localUsername: Username to use when logging onto the machine locally [string]
     *      localPassword: Password to use when logging onto the machine locally [string]
     *      recipients: An object where the keys consists the names of the computers to publish, and the values are email addresses of the recipients [object]
     *      mailOptions: Truthy value will cause the generated details to mailed to the holders.
     *          An object can be used:
     *              {
     *                  reportTo: An email address to send a report of the publishing to.
     *                  ccTo: An address to which all mail should be CC:d
     *              }
     *  }
     * @param {*} cb A callback function that can be used to perform steps once the publishing is done.
     */
    publish(settings, cb) {
        let dataObject = {
            subject: settings.subject,
            endDate: settings.endDate,
            localUsername: settings.localUsername,
            localPassword: settings.localPassword,
            recipients: settings.recipients,
            mailOptions: settings.mailOptions
        }
        
        let dataJSON = JSON.stringify(dataObject)

        let req = new XMLHttpRequest()
        req.open('POST', `/deploy/computer/publish`)
        req.setRequestHeader('Content-Type', 'application/json')
        
        req.onload = (res) => {
            try {
                //console.log(res.target.response)
                let data = JSON.parse(res.target.response)
                console.log(data)
                cb(data)
            } catch(err) {
                console.log(err)
                cb()
            }
        }

        req.send(dataJSON)

    }

    loadComputers(cb) {
        console.log('Updating computers')
        let req = new XMLHttpRequest()
        let computers = []
        req.open('GET', `/deploy/computer`)
        req.onload = res => {
            switch (res.target.status) {
                case 200:
                    JSON.parse(res.target.response).forEach(computer => {
                        computers.push(computer)
                    })
                    break
                case 401:
                    console.log('Unauthorized')
                    break
                default:
                    console.log('Unrecognized status')
                    break;
            }
            if (typeof cb === 'function') {
                cb(computers)
            }
        }
        req.onerror = err => {
            console.log(err)
        }
        req.send()
    }
}

export default ComputerManager